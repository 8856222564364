"use strict";

// Class Definition
var KTLoginGeneral=function() {

    var login = $('#kt_login');

    var showErrorMsg = function(form, type, msg) {
        var alert = $('<div class="alert alert-' + type + ' alert-dismissible" role="alert">\
			<div class="alert-text">'+msg+'</div>\
			<div class="alert-close">\
                <i class="flaticon2-cross kt-icon-sm" data-dismiss="alert"></i>\
            </div>\
		</div>');

        form.find('.alert').remove();
        alert.prependTo(form);
        //alert.animateClass('fadeIn animated');
        KTUtil.animateClass(alert[0], 'fadeIn animated');
        alert.find('span').html(msg);
    }

    // Private Functions
    var displaySignUpForm = function() {
      $("#kt_login").removeClass('kt-login--forgot');
      $("#kt_login").removeClass('kt-login--signin');

      $("#kt_login").addClass('kt-login--signup');
      KTUtil.animateClass($("#kt_login").find('.kt-login__signup'), 'flipInX animated');
    }

    var displaySignInForm = function() {
      $("#kt_login").removeClass('kt-login--forgot');
      $("#kt_login").removeClass('kt-login--signup');

      $("#kt_login").addClass('kt-login--signin');
      KTUtil.animateClass($("#kt_login").find('.kt-login__signin'), 'flipInX animated');
        //login.find('.kt-login__signin').animateClass('flipInX animated');
    }

  var displayForgotForm = function () {
    debugger;
      $("#kt_login").removeClass('kt-login--signin');
      $("#kt_login").removeClass('kt-login--signup');

      $("#kt_login").addClass('kt-login--forgot');
        //login.find('.kt-login--forgot').animateClass('flipInX animated');
        /*KTUtil.animateClass(login.find('.kt-login__forgot')[0], 'flipInX animated');*/
      KTUtil.animateClass($("#kt_login").find(".kt-login__forgot"), 'flipInX animated');

    }

    var handleFormSwitch = function() {
      $('#kt_login_forgot').click(function (e) {
        debugger;
            e.preventDefault();
            displayForgotForm();
        });

      $('#kt_login_forgot2').click(function (e) {
        debugger;
        e.preventDefault();
        displayForgotForm();
      });

        $('#kt_login_forgot_cancel').click(function(e) {
            e.preventDefault();
            displaySignInForm();
        });

        $('#kt_login_signup').click(function(e) {
            e.preventDefault();
            displaySignUpForm();
        });

        $('#kt_login_signup_cancel').click(function(e) {
            e.preventDefault();
            displaySignInForm();
        });
    }

    var handleSignInFormSubmit = function() {
        $('#kt_login_signin_submit').click(function(e) {
            e.preventDefault();
            var btn = $(this);
            var form = $(this).closest('form');

            form.validate({
                rules: {
                    email: {
                        required: true,
                        email: true
                    },
                    password: {
                        required: true
                    }
                }
            });

            if (!form.valid()) {
                return;
            }

            btn.addClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', true);

            form.ajaxSubmit({
                url: '',
              success: function (response, status, xhr, $form) {
                debugger;
                	// similate 2s delay
                	setTimeout(function() {
	                    btn.removeClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', false);
	                    showErrorMsg(form, 'danger', 'Incorrect username or password. Please try again.');
                    }, 2000);
              },
              error: function (response, status, xhr, $form) {
                debugger;
                // similate 2s delay
                setTimeout(function () {
                  btn.removeClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', false);
                  showErrorMsg(form, 'danger', 'Incorrect username or password. Please try again.');
                }, 2000);
              }
            });
        });
    }

    var handleSignUpFormSubmit = function() {
        $('#kt_login_signup_submit').click(function(e) {
            e.preventDefault();

            var btn = $(this);
            var form = $(this).closest('form');

            form.validate({
                rules: {
                    fullname: {
                        required: true
                    },
                    email: {
                        required: true,
                        email: true
                    },
                    password: {
                        required: true
                    },
                    rpassword: {
                        required: true
                    },
                    agree: {
                        required: true
                    }
                }
            });

            if (!form.valid()) {
                return;
            }

            btn.addClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', true);

            form.ajaxSubmit({
                url: '',
                success: function(response, status, xhr, $form) {
                	// similate 2s delay
                	setTimeout(function() {
	                    btn.removeClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', false);
	                    form.clearForm();
	                    form.validate().resetForm();

	                    // display signup form
	                    displaySignInForm();
                    var signInForm = $("#kt_login").find('.kt-login__signin form');
	                    signInForm.clearForm();
	                    signInForm.validate().resetForm();

	                    showErrorMsg(signInForm, 'success', 'Thank you. To complete your registration please check your email.');
	                }, 2000);
                }
            });
        });
    }

    var handleForgotFormSubmit = function() {
        $('#kt_login_forgot_submit').click(function(e) {
            e.preventDefault();

            var btn = $(this);
            var form = $(this).closest('form');

            form.validate({
                rules: {
                    email: {
                        required: true,
                        email: true
                    }
                }
            });

            if (!form.valid()) {
                return;
            }

            btn.addClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', true);

            form.ajaxSubmit({
                url: '',
                success: function(response, status, xhr, $form) {
                	// similate 2s delay
                	setTimeout(function() {
                		btn.removeClass('kt-spinner kt-spinner--right kt-spinner--sm kt-spinner--light').attr('disabled', false); // remove
	                    form.clearForm(); // clear form
	                    form.validate().resetForm(); // reset validation states

	                    // display signup form
	                    displaySignInForm();
                    var signInForm = $("#kt_login").find('.kt-login__signin form');
	                    signInForm.clearForm();
	                    signInForm.validate().resetForm();

	                    showErrorMsg(signInForm, 'success', 'Password recovery instruction has been sent to your email.');
                	}, 2000);
                }
            });
        });
    }

    // Public Functions
    return {
        // public functions
        init: function() {
               handleFormSwitch();
            //handleSignInFormSubmit();
            //  handleSignUpFormSubmit();
             handleForgotFormSubmit();
        }
    };
}();

function CallLoginGeneral() {

jQuery(document).ready(function() {
  KTLoginGeneral.init();

});
 
}
// Class Initialization
//jQuery(document).ready(function() {
//  KTLoginGeneral.init();
 
//});
 
//$(window).on('load', function (e) {
//  KTLoginGeneral.init();
//})



 
